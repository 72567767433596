<template>
  <ion-page>

    <!-- Page Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="create-post" />
      <!-- Home Feed -->
      <div class="app-max-width">
        <!-- If User is Authorized -->
        <div v-if="isAuth === true">
          <!-- Backend Errors -->
          <div v-if="isError === true">
            <div v-for="error in errors" :key="error">
              <div v-for="el in error" :key="el">
                <ion-item>
                  <div tabindex="0"></div><!-- bug fix for safari 14 -->
                  <ion-text color="danger">{{el}}</ion-text>
                </ion-item>
              </div>
            </div>
          </div>
          <!-- Image -->
          <ion-item v-if="imageUrl != null">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-img :src="imageUrl" />
          </ion-item>
          <!-- Body -->
          <TextWrapper 
            :store="store.state"
            input-type="textarea"
            :is-mentions="true" 
            :is-hashtags="true" 
            :has-button="false" 
            :set-delimeter="null"
            @input-data="getInputData" />
          <!-- URL -->
          <div v-if="showUrlForm === true">
            <LinkForm :store="store.state" @link-data="getLinkData" />
            <div v-if="isLinkSet === true">
              <ion-button size="small" color="danger" @click.prevent="removeLink()">Remove Link</ion-button>
            </div>
          </div>
          <!-- Actions -->
          <ion-item lines="none">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-button v-if="imageUrl === null" slot="start" expand="full" size="small" color="light" @click="takePicture()">
              <ion-icon :icon="image"></ion-icon>
            </ion-button>
            <ion-button v-if="imageUrl != null" slot="start" expand="full" size="small" color="danger" @click="imageUrl = null">
              <ion-icon :icon="trash"></ion-icon>
            </ion-button>
            <ion-button slot="start" expand="full" size="small" color="light" @click.prevent="showUrlForm = !showUrlForm">
              <ion-icon :icon="link"></ion-icon>
            </ion-button>
            <ion-button slot="end" expand="full" size="small" color="primary" @click.prevent="storePost()">
              Post
            </ion-button>
          </ion-item>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonItem, IonButton, IonText, loadingController, toastController, IonIcon, IonImg } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import doAuth from '../../services/auth';
import ModalHeader from '../../components/ModalHeader.vue';
import LinkForm from '../../components/LinkForm.vue'
import TextWrapper from '../../components/TextWrapper.vue'
import { image, trash, link } from 'ionicons/icons';
import apiClient from '../../services/api';
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
const { Camera } = Plugins;

export default defineComponent({
  name: 'CreatePost',
  components: {
    IonContent,
    IonPage,
    ModalHeader,
    IonItem, 
    IonButton, 
    IonText, 
    IonIcon, 
    IonImg,
    LinkForm,
    TextWrapper
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const isError = ref(false);
    const errors = ref([]);
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const authToken = computed(() => store.state.authToken);
    const title = ref(null);
    const status = ref(1);
    const timeout = { default: 6000 }
    const toastMessage = ref(null);
    const imageUrl = ref(null);
    const showUrlForm = ref(false);
    const url = ref(null);
    const linkImage = ref(null);
    const linkTitle = ref(null);
    const linkDescription = ref(null);
    const isLinkSet = ref(false);
    const bodyText = ref(null);
    const mentions = ref(null);
    const hashtags = ref(null);
    const collectionData = ref([]);

    function updateRoute(val) {
      router.push(val)
    }

    onMounted(() => {
      collectionData.value.push({
        id: 1,
        name: 'John Mancuso',
        username: 'jmancuso'
      })
    })

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
          {
            icon: 'close',
            role: 'cancel',
            handler: () => {

            }
          }
        ]
      })
      return toast.present();
    }

    async function takePicture() {
      let isAvailable = true;
      if (!isAvailable) {
        // Have the user upload a file instead
        alert("No Camera Aailable");
      } else {
        // Otherwise, make the call:
        try {
          const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Prompt
          });
          console.log("image", image);
          // image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
          imageUrl.value = image.dataUrl;
          // can be set to the src of an image now
          console.log(image);
        } catch (e) {
          console.log("error", e);
        }
      }
    }

    function resetForm() {
      title.value = null
      bodyText.value = null
      status.value = 1
      imageUrl.value = null
      showUrlForm.value = false
      url.value = null;
      linkImage.value = null;
      linkTitle.value = null;
      linkDescription.value = null;
      isLinkSet.value = false;
      mentions.value = null;
      hashtags.value = null;
    }

    function storePost() {
      presentLoading()
      apiClient.post('/api/store/post', 
        {
          title: title.value,  
          body: bodyText.value, 
          status: status.value, 
          post_type: 'post' ,
          image: imageUrl.value,
          url: url.value,
          link_image: linkImage.value,
          link_title: linkTitle.value,
          link_description: linkDescription.value,
          external: 0
          //mentions: mentions.value,
          //hashtags: hashtags.value       
        },
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          // Success
          if(response.data.message == 'success') {
            toastMessage.value = 'Your post has been created.'
            openToast()
            isError.value = false
            errors.value = []
            resetForm()
            // Refresh feed
            store.commit('setFeedTrigger', true);
            // Clear text wrapper
            store.commit('setGenericTrigger', true)
            // Close modal
            store.commit('setDismissModal', true)
            // Redirect home
            router.push('home')

          }
          // Fail Validation
          if(response.data.message == 'fail-validation') {
            toastMessage.value = 'There was a problem.'
            openToast()
            isError.value = true
            errors.value = response.data.errors            
          }
          // Fail Auth
          if(response.data.message == 'fail-auth') {
            toastMessage.value = 'You must be signed in to update your profile.'
            openToast()
          }
        }).catch(error => {
          // credentials didn't match
          isError.value = true
          errors.value = error.response.data.errors
        });        

    }

    function removeLink() {
      isLinkSet.value = false;
      showUrlForm.value = false;
    }

    function getLinkData(e) {
      //if(e.length > 0) {
        isLinkSet.value = true;
        url.value = e.url;
        linkImage.value = e.linkImage;
        linkTitle.value = e.linkTitle;
        linkDescription.value = e.linkDescription;        
      //}

    }

    function getInputData(e) {
      if(e) {
        bodyText.value = e.text
        mentions.value = e.mentions
        hashtags.value = e.hashtags
      }
      //console.log('test' + e.text)
    }

    return {
       authUser, authToken, title, status, presentLoading, storePost, isError, errors, resetForm, router, image, trash, imageUrl, takePicture, link, showUrlForm, getLinkData, removeLink, url, linkImage, linkTitle, linkDescription, isLinkSet, getInputData, bodyText, mentions, hashtags, store, updateRoute
    }
  }
});
</script>

<style scoped>

</style>